import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import styled from "styled-components";
import { withPrefix } from "gatsby";
import {
  sizes,
  colorAliases,
  fontSizes,
  MOBILEBREAKPOINT,
} from "../style/design-system";
import useSiteMetadata from "../components/SiteMetadata";
import OuterContainer from "../components/OuterContainer";
import SigningCompleteIllustration from "../components/SigningCompleteIllustration";

const SectionInnerContainer = styled.div`
  width: 100%;
  max-width: ${sizes.siteWidth};
  margin: 0 auto;
  padding: ${sizes["2xl"]} ${sizes.lg} ${sizes.lg} ${sizes.lg};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    padding: ${sizes.lg};
  }
`;

const SectionHeader = styled.h2`
  font-weight: 700;
  font-size: ${fontSizes["5xl"]};
  margin-bottom: ${sizes["2xl"]};

  @media (max-width: ${MOBILEBREAKPOINT}) {
    font-size: ${fontSizes["5xl"]};
  }
`;

const SectionTagline = styled.h3`
  margin: 0 auto ${sizes["2xl"]} auto;
  font-weight: 300;
  font-size: ${fontSizes["2xl"]};
  text-align: center;

  @media (max-width: ${MOBILEBREAKPOINT}) {
    margin: 0 auto ${sizes["lg"]} auto;
  }
`;

const InfoCloseTab = styled.p`
  font-size: ${fontSizes["lg"]};
`;

const Illustration = styled(SigningCompleteIllustration)`
  width: 100%;
`;

const ImageContainer = styled.div`
  display: block;
  width: fit-content;
  max-width: 900px;
  margin: 0 auto ${sizes["2xl"]} auto;
`;

const StyledLink = styled.a`
  color: ${colorAliases.turqoise};

  &:visited {
    color: ${colorAliases.turqoise};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const languages = {
  en: {
    header: `Signing complete!`,
    noLinkTagline: `dflow.com`,
    linkTagline: ``,
    closTabInfo: `You may close this browser tab now`,
  },
  no: {
    header: `Ferdig signert!`,
    noLinkTagline: `Få dokumenter raskt signert med`,
    linkTagline: `dflow.com`,
    closTabInfo: `Du kan lukke denne nettleserfanen nå`,
  },
};

export const CompletePageTemplate = ({ location }) => {
  const { title, siteUrl } = useSiteMetadata();
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    if (window) {
      const userLang = window.navigator.language.substring(0, 2);

      if (userLang) {
        switch (userLang) {
          case "no":
          case "nb":
          case "nn":
            setLanguage(languages["no"]);
            break;
          default:
            setLanguage(languages.en);
        }
      } else {
        setLanguage(languages.en);
      }
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>{`Complete | ${title}`}</title>
        <link rel="canonical" href={siteUrl + location.pathname} />
        <link
          rel="stylesheet"
          href="https://use.typekit.net/fbe6uci.css"
        ></link>
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-32x32.png`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon-16x16.png`}
          sizes="16x16"
        />
        <meta name="theme-color" content="#fff" />
        <meta name="robots" content="noindex"></meta>
      </Helmet>
      <OuterContainer>
        <SectionInnerContainer>
          {language ? (
            <>
              <SectionHeader>{language.header}</SectionHeader>
              <ImageContainer>
                <Illustration color={colorAliases.turqoise} />
              </ImageContainer>
              <SectionTagline>
                {language.noLinkTagline}{" "}
                {language.linkTagline && (
                  <StyledLink href="https://dflow.com/">dflow.com</StyledLink>
                )}
                <InfoCloseTab>{language.closTabInfo}</InfoCloseTab>
              </SectionTagline>
            </>
          ) : null}
        </SectionInnerContainer>
      </OuterContainer>
    </div>
  );
};

const CompletePage = ({ location }) => {
  return <CompletePageTemplate location={location} />;
};

export default CompletePage;
