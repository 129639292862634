import React from "react";

export default ({ className, color, onClick }) => (
  <svg
    viewBox="0 0 400 400"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    onClick={onClick}
  >
    <g transform="matrix(2,0,0,2,0,0)">
      <path
        d="M51.5,98.662a16.526,16.526,0,0,0-3.812,4.194c-4.626-2.513-10.041-2.121-15.158,2.279-6.625,5.828-5.451,19.429,3.828,21.117a13.8,13.8,0,0,0,3.515.189.851.851,0,0,0,.045-1.7c-5.868-.717-8.749-4.294-8.689-9.613a10.681,10.681,0,0,1,3.193-7.821c3.48-3.113,7.823-4.4,12.178-2.662-.239.429-.465.854-.663,1.262a.87.87,0,1,0,1.468.935c.268-.323.632-.763,1.041-1.265a10.687,10.687,0,0,1,1.14.773.865.865,0,1,0,1.113-1.324c-.352-.325-.713-.626-1.081-.911,1.131-1.43,2.355-3.046,3.128-4.283C53.361,98.854,52.443,97.988,51.5,98.662Z"
        fill={color}
      ></path>
      <path
        d="M38.054,115.379a18.647,18.647,0,0,1,2.7-2.681.863.863,0,1,1,1.346,1.079,17.638,17.638,0,0,1-2.948,4.784,1.444,1.444,0,0,1-2.292-.134c-.233-.345-.879-1-1.535-1.746-1.033-1.122-2.912-3.672-1.658-4.384C34.651,111.74,37.278,114.56,38.054,115.379Z"
        fill={color}
      ></path>
      <path
        d="M23.94,102.634c-.015,1.021-.033,4.739-.034,4.762-.1,1.4-.3,3.514-1.279,3.56-1.92.046-1.625-7.885-1.525-9.8a1.459,1.459,0,0,1,1.436-1.38c1.917.048,8.844-.144,8.722,1.6-.057.817-.48.922-4.8,1.168C25.62,102.6,24.785,102.612,23.94,102.634Z"
        fill={color}
      ></path>
      <path
        d="M146.653,100.741c-.139-.517-.619-.4-1.58-.3-3.8.461-8.9,1.1-12.388-.406-6.963-2.84-4.764-10.958-4.35-18.035a.872.872,0,0,0-.823-.923,50.279,50.279,0,0,0-5.975.078c1.384-5.363,3.76-11.045,7.076-19.448a.508.508,0,0,0-.256-.679.531.531,0,0,0-.69.267c-1.746,3.817-5.884,11.32-8.11,20.572a.879.879,0,0,0,.867,1.079c2.068-.029,3.919-.218,6.086-.156-.524,6.855-2.436,15.6,5.552,18.769a17.581,17.581,0,0,0,4.462.924c.159,3.41.066,6.8.445,10.614a.536.536,0,0,0,.578.478.52.52,0,0,0,.479-.567c-.19-3.128-.022-7.045-.028-10.374a15.931,15.931,0,0,0,7.375-.891C146.176,101.427,146.785,101.292,146.653,100.741Z"
        fill={color}
      ></path>
      <path
        d="M136.2,89.1a3.68,3.68,0,0,0,2.7,1.936c2.673.428,4.1-1.758,4.617-4.428a22.465,22.465,0,0,0,.234-2.57.527.527,0,0,0-1.035-.2c-.988,3.131-1.225,3.81-1.635,4.462a1.828,1.828,0,0,1-2.292.946,6.3,6.3,0,0,1-1.791-.8A.518.518,0,0,0,136.2,89.1Z"
        fill={color}
      ></path>
      <path
        d="M133.76,58.557c1.113-1.25,2.716-.889,4.128,0,1.868,1.155,1.875,1.931,2.481,1.536.774-.493-.8-2.332-1.536-2.882-2.713-2.163-6-1.187-6.809,1.09-.27.729-.438,1.41.045,1.591C132.624,60.124,132.674,59.507,133.76,58.557Z"
        fill={color}
      ></path>
      <path
        d="M133.736 66.874 A1.88 3.093 0 1 0 137.496 66.874 A1.88 3.093 0 1 0 133.736 66.874 Z"
        fill={color}
      ></path>
      <path
        d="M159.594,42.766a27.117,27.117,0,0,0-8.934-.278c-.9-.612-12.249-8-15.877-10.436-4.517-3.032-8.543-6.516-13.818-4.405-9.41,3.763-13.337,19.143-3.349,24.51,3.521,1.9,7.447,1.538,11.415,1.479,5.336-.108,10.344-.258,15.71-.79a17.542,17.542,0,0,0,13.6,15.988c1.972.5,1.272.091,2.32.041,2.053-.1,5.327-.213,6.873,1.14a5.7,5.7,0,0,1,.712,7.287,8.875,8.875,0,0,1-7.154,3.36.888.888,0,0,0-.868,1.2,22.353,22.353,0,0,1,.913,2.938c.123.5,1.949,8.736,2.125,9.612a83.178,83.178,0,0,1,1.691,11.682.523.523,0,0,0,.545.5.516.516,0,0,0,.5-.545,73.887,73.887,0,0,0-1.575-14.159c1.81-4.413,3.92-8.136,4.942-13.2.1-.124.214-.239.3-.367a7.568,7.568,0,0,0,1.008-6.367,38.216,38.216,0,0,0,.713-5.027C172.405,56.306,170,44.937,159.594,42.766ZM124.067,31.937a7.713,7.713,0,0,0-3.772,2.414,13.353,13.353,0,0,0-2.914,6.008.517.517,0,0,1-.557.489.53.53,0,0,1-.489-.556c-.149-5.149,3.822-9.406,7.365-9.98.75-.134,2.924-.124,2.859.723C126.51,31.654,126.031,31.341,124.067,31.937Z"
        fill={color}
      ></path>
      <path
        d="M194.442,171.562l-.085.006-.173-.666a.509.509,0,0,0,.392-.508c-.315-23.88-6.414-50.06-26.647-62.739l-.611-.111c-4.929.964-9.609,3.226-14.13,5.173-4.964,2.125-9.746,4.074-14.174,6.342a25.7,25.7,0,0,1-6.253,2.648,3.32,3.32,0,0,1-1.513,0c-.253-.567,1.506-2.711,1.913-3.182a.869.869,0,0,0-1.146-1.29,75.172,75.172,0,0,0-27.469,33.51.858.858,0,0,0,.133.9,45.709,45.709,0,0,0,8.712,8.644.866.866,0,0,0,1.212-.222c1.635-2.316,3.624-4.463,5.308-6.575.1.1,20.672,18.407,25.667,22.273a.845.845,0,0,0,.667.167c6.066-1.048,12.4-.663,18.569-.968,10.117-.539,19.414-1.393,29.717-2.381a.513.513,0,0,0-.089-1.023Z"
        fill={color}
      ></path>
      <path
        d="M122.866,148.609c6.9-8.348,9.6-11.355,20.26-22.018a.523.523,0,1,0-.712-.767,112.085,112.085,0,0,0-17.935,18.357c-1.084,1.49-1.724,2.609-2.459,3.828a.526.526,0,0,0,.122.723A.518.518,0,0,0,122.866,148.609Z"
        fill={color}
      ></path>
      <path
        d="M54.123,125.489a24.608,24.608,0,0,0-1.5,11.337c1.544,16.735,17.016,33.893,23.264,42.233a.526.526,0,1,0,.856-.611c-4.622-6.776-8.936-12.348-12.783-18.224-6.583-10.2-12.189-22.748-8.856-34.413A.515.515,0,0,0,54.123,125.489Z"
        fill={color}
      ></path>
      <path
        d="M119.023,164.771c-.249-.162-3.123-1.879-3.377-2.029,2.677-3.213,2.648-3.573,4.678-6.246a.532.532,0,0,0-.089-.734.54.54,0,0,0-.745.089c-1.51,1.872-3.683,4.6-5.251,6.253a128.476,128.476,0,0,1-23.075-19.07c-.534-.556-4.174-4.129-5.385-5.485a.521.521,0,0,0-.79.679A90.147,90.147,0,0,0,98.3,152.291a112.708,112.708,0,0,0,9.146,7.232,127.682,127.682,0,0,0,11.036,6.861c1.738.789,2.312.924,2.56.478C121.344,166.309,120.627,165.937,119.023,164.771Z"
        fill={color}
      ></path>
      <path
        d="M57.546,126.058a11.385,11.385,0,0,0-.189,5.163c.075.346.62,2.569,1.357,2.392.493-.138.4-.409.278-2.626a15.969,15.969,0,0,1,.167-4.539c.145-1.3.086-1.733-.289-1.858C58.3,124.4,57.675,125.709,57.546,126.058Z"
        fill={color}
      ></path>
      <path
        d="M61.663,126.762a11.011,11.011,0,0,0,.578,3.437c.24.641.92,2.224,1.558,1.959.521-.209.317-.643.033-2.4a17.155,17.155,0,0,1-.423-3.048c-.02-.563,0-2.474-.712-2.559S61.632,125.783,61.663,126.762Z"
        fill={color}
      ></path>
      <path
        d="M83.631,126.953c-1.936-2.812-5-1.853-7.255-2.336-3.34-.7-6.066-4.576-6.286-7.911a.875.875,0,0,0-.723-.8,8.17,8.17,0,0,0-8.433,4.851.842.842,0,0,0,.868,1.168c3.713-.459,6.093,3.7,5.7,7.2a.487.487,0,0,0-.1.112c-.192.24-.054,1.112.345,1.224a.436.436,0,0,0,.334-.034c2.513,2.478,2.118,7.716,6.508,9.2C81.343,141.87,87.618,132.741,83.631,126.953Z"
        fill={color}
      ></path>
      <path
        d="M50.945,131.79c-1.557.05-4.039.047-5.251-.89-2.783-2.126-1-8.126-.59-11.37.341-2.762.35-6.728,2.17-8.879.725-.841,2.207-.959,3.326-1.045a106.1,106.1,0,0,1,25.556,2.114c2.083.394,4.267.954,5.174,2.881,1.088,2.368.008,6.151-.267,8.834a.526.526,0,0,0,1.045.122c.408-2.922,1.71-6.644.5-9.523-1.052-2.473-3.519-3.315-6.141-3.872a100.459,100.459,0,0,0-25.99-2.314c-1.686.108-3.465.381-4.551,1.669-2.177,2.58-2.153,6.5-2.547,9.8-.42,3.578-3.154,12.722,3.994,13.563A18.508,18.508,0,0,0,51,132.825a.512.512,0,0,0,.489-.545A.523.523,0,0,0,50.945,131.79Z"
        fill={color}
      ></path>
      <path
        d="M34.941,39.845c3.1-.681,7.13-.653,24.855-2.492,1.68-.167,3.349-.323,5.04-.4a.523.523,0,1,0,0-1.046c-6.967-.3-10.562.372-16.989.9-5.514.493-8.665.3-13.2,2.048a.513.513,0,0,0-.356.634A.53.53,0,0,0,34.941,39.845Z"
        fill={color}
      ></path>
      <path
        d="M23.169,56.392c5.1-.523,47.831-3.13,48.352-3.148,5.079-.224,9.911.066,15.254-.034a.529.529,0,1,0,0-1.057c-5.1-.111-9.734-.625-15.309-.545-6.629.132-34.3,2.027-37.984,2.348-3.631.309-7.061.8-10.458,1.413a.517.517,0,1,0,.145,1.023Z"
        fill={color}
      ></path>
      <path
        d="M23.956,74.9c3.959-.449,47.86-3.14,48.353-3.16,4.659-.2,10.435.072,15.253-.033a.532.532,0,0,0,.534-.523.525.525,0,0,0-.534-.523c-5.375-.134-9.768-.636-15.309-.556-7.285.181-34.684,2.067-37.983,2.347-3.036.291-5.662.566-10.458,1.424A.517.517,0,0,0,23.956,74.9Z"
        fill={color}
      ></path>
      <path
        d="M26.031,92.274c3.959-.449,47.86-3.14,48.353-3.16,4.478-.2,10.5.056,15.253-.033a.532.532,0,0,0,.534-.523.525.525,0,0,0-.534-.523c-5.393-.134-9.773-.636-15.309-.556-7.286.181-34.684,2.067-37.983,2.347-3.036.291-5.662.566-10.458,1.424a.514.514,0,0,0-.445.579A.533.533,0,0,0,26.031,92.274Z"
        fill={color}
      ></path>
      <path
        d="M41.638,139.445c-8.333.061-19.919.992-28.248,1.535A3.748,3.748,0,0,1,9.429,137.5c-.769-12.311-.647-32.645-.89-37.783-.233-4.361-.567-8.723-.69-13.084C7.571,76.039,6.826,30.98,6.793,27.865a3.574,3.574,0,0,1,3.738-3.672c3.36.122,6.731.078,10.091-.022,5.029-.156,72.462-1.714,73.519-1.725a3.606,3.606,0,0,1,3.571,3.516C98.1,37.58,99.39,48.241,99.882,59.3c.7,15.125.314,30.478.756,45.616.21,7.971,1.138,18.17,1.714,27.035a3.9,3.9,0,0,1-3.772,4.094c-7.519.217-6.31.093-8.923.268a.529.529,0,0,0,.067,1.056c1.839-.125,3.011-.052,4.907-.089l3.983-.111a5.035,5.035,0,0,0,4.906-5.285c-.323-6.007-1.446-24.487-1.5-27.013-.234-10.636-.078-21.284-.167-31.931-.239-24.621-1.9-29.932-2.414-47.029a5.336,5.336,0,0,0-5.307-5.184c-6.4.066-10.678.448-15.988.489-8.511.067-36.86.379-42.534.568-8.007.251-16.838.947-25.01.645a5.361,5.361,0,0,0-5.574,5.463c.049,4.586-.119,8.928.066,13.406.178,4.54.746,34.791,1.035,45.382.133,5.007.579,9.991.79,14.987.333,7.464.012,21.995,1.023,35.936a5.186,5.186,0,0,0,5.552,4.784c9.126-.685,18.267-1.608,28.171-1.9a.51.51,0,0,0,.5-.523A.519.519,0,0,0,41.638,139.445Z"
        fill={color}
      ></path>
    </g>
  </svg>
);
